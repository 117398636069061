import React, { Fragment, useState, useEffect } from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import PostCard from "../components/PostCard"
import Categories from "@components/Categories"
import heroImage from "../images/hero-image.svg"
import FeaturedSection from "../components/Sections/FeaturedSection"
import HotSection from "../components/Sections/HotSection"
import { ThemeProvider } from "../components/themeContext"
import { Tab } from "@headlessui/react"
import Header from "../components/Header"
import HeaderPromotion from "../components/HeaderPromotion"
import Hero from "../components/Hero"
import Features from "../components/Features"
import Footer from "@components/Footer"
import WhyUs from "../components/WhyUs"
import Helmet from "react-helmet"
import LoadChat from "@components/Chat"

import "../css/tailwind.css"

export default () => {
  const [LazyComponent, setLazyComponent] = useState(null)
  const loadLazyComponent = async () => {
    if (LazyComponent !== null) {
      return
    }

    // import default export of `lazy.js` module
    const { default: lazyModule } = await import("../components/FontCard")
    // create React component so it can be rendered
    const LazyElement = React.createElement(lazyModule, {
      // pass props to component here
    })
    setLazyComponent(LazyElement)
  }

  useEffect(() => {
    setTimeout(() => {
      loadLazyComponent()
    }, 50)
  }, []) //eslint-disable-line

  return (
    <ThemeProvider>
      <Layout>
        <LoadChat />
        <HeaderPromotion />
        <Header />
        <Hero />
        <div class="container my-12 mx-auto px-4 md:px-8">
          <div class="flex flex-wrap -mx-1 lg:-mx-4mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-8 lg:grid-cols-4 lg:gap-x-8">
            <PostCard />
          </div>
          <div class="w-full text-center p-4">
            <Link to="/search" class="btn btn-primary mt-12">
              Browse All Domains
            </Link>
          </div>
        </div>
        {/* <div class="container my-12 mx-auto px-4 2xl:max-w-7xl max-w-6xl">
          <Tab.Group>
            <div class="tabs block">
              <Tab.List>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <a
                      className={
                        selected
                          ? "tab tab-lifted tab-lg tab-active bg-transparent"
                          : "tab tab-lifted tab-lg"
                      }
                    >
                      With Logo
                    </a>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <a
                      className={
                        selected
                          ? "tab tab-lifted tab-lg tab-active bg-transparent"
                          : "tab tab-lg tab-lifted"
                      }
                    >
                      No Logo
                    </a>
                  )}
                </Tab>
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel>
                  <div class="flex flex-wrap -mx-1 lg:-mx-4 mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-3 2xl:grid-cols-4 lg:gap-x-8">
                    <PostCard />
                  </div>
                </Tab.Panel>
                <Tab.Panel>
                  <div class="flex flex-wrap -mx-1 lg:-mx-4 mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-3 2xl:grid-cols-4 lg:gap-x-8">
                    <Fragment>{LazyComponent}</Fragment>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </div>
          </Tab.Group>
        </div> */}
        <div class="container my-12 mx-auto px-4 max-w-6xl 2xl:max-w-7xl pb-16">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold tracking-tight text-base-content sm:text-4xl">
              Categories
            </h2>
          </div>
          <div class="-mx-1 lg:-mx-4 mt-12 grid grid-cols-1 sm:grid-cols-2 gap-x-4 sm:gap-x-6 sm:gap-y-8 lg:grid-cols-3 2xl:grid-cols-4 lg:gap-x-8">
            <Categories />
          </div>
        </div>
        {/* <Features /> */}
        <WhyUs />
        <Footer />
      </Layout>
    </ThemeProvider>
  )
}
