import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { HiSearch } from "react-icons/hi"
import pattern from "@static/contour-pattern.svg"

export default function Hero() {
  const [LazyComponent, setLazyComponent] = useState(null)
  const loadLazyComponent = async () => {
    if (LazyComponent !== null) {
      return
    }

    // import default export of `lazy.js` module
    const { default: lazyModule } = await import("@components/TextTransition")
    // create React component so it can be rendered
    const LazyElement = React.createElement(lazyModule, {
      // pass props to component here
    })
    setLazyComponent(LazyElement)
  }

  useEffect(() => {
    setTimeout(() => {
      loadLazyComponent()
    }, 50)
  }, []) //eslint-disable-line

  // Search keyword forward:

  const [searchInput, setSearchInput] = useState("")
  function handleSearchInput(e) {
    setSearchInput(e.target.value)
  }
  const handleKeypress = e => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      this.btn.click()
    }
  }

  return (
    <div>
      {/* <div>
        <div
          className="gradient absolute h-full w-full top-0"
          style={{
            background: "linear-gradient(#4f46e56b, #9198e500)",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
            width: "100%",
          }}
        />
        <div
          className="absolute h-full w-full top-0"
          style={{
            backgroundImage: "url(" + pattern + ")",
            backgroundSize: "100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
            width: "100%",
          }}
        />
      </div> */}
      <div class="hero m-auto py-16">
        <div class="text-center hero-content">
          <div class="max-w-7xl">
            <h1 className="text-4xl text-base-content tracking-tight font-extrabold sm:text-5xl md:text-6xl">
              <span className="sm:block">Find Cool Domain Names</span>
              {/* space */}
              <span className="inline">
                {" "}
                For Your{" "}
                <span class="inline text-indigo-600 ">{LazyComponent}</span>
              </span>
            </h1>
            <p class="mt-8 max-w-md mx-auto sm:text-lg md:mt-5 md:text-xl md:max-w-3xl text-base-content">
              We sold over 2000+ brandable and valuable business names from
              first hand with free logos.
            </p>
            <div class="flex items-start w-full mt-12 lg:mx-auto lg:justify-center lg:w-2/3 relative">
              <div class="text-left w-full" bis_skin_checked="1">
                <div class="absolute top-5 bottom-0 left-3 z-10">
                  <HiSearch className="dark:text-gray-400 text-3xl cursor-pointer" />
                </div>
                <input
                  onChange={handleSearchInput}
                  onKeyPress={handleKeypress}
                  placeholder="Search by keyword..."
                  type="text"
                  id="hero-field"
                  name="hero-field"
                  class="shadow-lg flex-grow w-full px-12 py-5 mb-4 text-base text-black transition duration-650 ease-in-out transform rounded-lg bg-blueGray-200 focus:outline-none focus:border-purple-500 sm:mb-0 focus:bg-white focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 md:text-lg md:px-12 text-base-content dark:bg-base-100"
                ></input>
              </div>
              <Link
                class="absolute right-2 top-2 md:top-2.5"
                to="/search"
                state={{ fromSearch: searchInput }}
              >
                <button
                  ref={node => (this.btn = node)}
                  class="btn min-h-12 text-md px-6"
                >
                  Search
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
