import React from "react"

export default () => {
  return (
    <div>
      <div className="bg-base-200">
        <div className="max-w-6xl 2xl:max-w-7xl mx-auto py-16 lg:py-24">
          <div className="max-w-3xl mx-auto text-center text-base-content">
            <h2 className="text-3xl font-extrabold">
              Get a hand-curated domain name created for success
            </h2>
            <p className="mt-4 text-lg">
              That people discover, remember, think about, talk about, search
              for, or navigate to a website
            </p>
          </div>
          <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:gap-x-8">
            <div class="card shadow-lg bg-base-100">
              <div class="card-body flex-row text-base-content">
                <svg
                  className="flex-shrink-0 h-6 w-6 text-green-500"
                  data-todo-x-description="Heroicon name: outline/check"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
                <div className="ml-3">
                  <dt className="text-lg leading-6 font-medium">
                    We create domain names for the future
                  </dt>
                  <dd className="mt-2 text-base">
                    At Brandshore we collect names with impressive future
                    investment opportunities. We focus on problem solving
                    long-term industries, rather than just riding a short trend.
                  </dd>
                </div>
              </div>
            </div>
            <div class="card shadow-lg bg-base-100">
              <div class="card-body flex-row text-base-content">
                <svg
                  className="flex-shrink-0 h-6 w-6 text-green-500"
                  data-todo-x-description="Heroicon name: outline/check"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
                <div className="ml-3">
                  <dt className="text-lg leading-6 font-medium">
                    Conscious Branding
                  </dt>
                  <dd className="mt-2 text-base">
                    New businesses are taking a mindful approach to branding,
                    that supports the positive growth. With our names we serve
                    businesses with conscious branding mindset.
                  </dd>
                </div>
              </div>
            </div>

            <div class="card shadow-lg bg-base-100">
              <div class="card-body flex-row text-base-content">
                <svg
                  className="flex-shrink-0 h-6 w-6 text-green-500"
                  data-todo-x-description="Heroicon name: outline/check"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
                <div className="ml-3">
                  <dt className="text-lg leading-6 font-medium">
                    Human appraisal is essential
                  </dt>
                  <dd className="mt-2 text-base">
                    Brand names cannot be valued by a software. We value names
                    based on brandability, similar sale history, keyword
                    popularity if applicable, or pattern into certain length
                    categories.
                  </dd>
                </div>
              </div>
            </div>

            <div class="card shadow-lg bg-base-100">
              <div class="card-body flex-row text-base-content">
                <svg
                  className="flex-shrink-0 h-6 w-6 text-green-500"
                  data-todo-x-description="Heroicon name: outline/check"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
                <div className="ml-3">
                  <dt className="text-lg leading-6 font-medium">
                    Other lessons
                  </dt>
                  <dd className="mt-2 text-base">
                    Serious businesses starts with a .com with real value,
                    spelled correctly, makes sense or something distinctive.
                    Modern sounding names are more likely to sell.
                  </dd>
                </div>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
  )
}
