import React, { Fragment } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage as Img } from "gatsby-plugin-image"
import FavoriteCardIcon from "@components/Favorite/FavoriteCardIcon"

export default () => {
  const { featured } = useStaticQuery(graphql`
    query {
      featured: allAirtable(
        limit: 12
        sort: { fields: id, order: ASC }
        filter: { data: { status: { ne: "sold" }, noimage: { eq: "true" } } }
      ) {
        edges {
          node {
            data {
              logo {
                localFiles {
                  id
                  childImageSharp {
                    gatsbyImageData(width: 300, placeholder: NONE)
                  }
                }
              }
              slug
              title
              price
            }
          }
        }
      }
    }
  `)

  return (
    <Fragment>
      {featured.edges.map(
        ({
          node: {
            data: { title, slug, price, logo },
          },
        }) => (
          <>
            <div class="card shadow-xl bg-base-100 w-full">
              <div class="card-body p-5">
                <div className="absolute bg-transparent right-4 top-4 z-10">
                  <div className="flex items-start justify-end">
                    <div className="h-7 flex items-center">
                      <div className="cursor-pointer rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                        <FavoriteCardIcon
                          name={title}
                          id={title}
                          price={price}
                          slug={slug}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <Link to={`/${slug}`} key={title}>
                  <div className="logo relative flex-1 text-center">
                    <Img
                      alt={title}
                      image={
                        logo
                          ? logo.localFiles[0].childImageSharp.gatsbyImageData
                          : null
                      }
                    />
                  </div>
                </Link>
                <Link to={`/${slug}`} key={title}>
                  <div className="flex-shrink-0 flex justify-between text-base-content">
                    <h2 className="card-title mb-0 mt-3">{title}</h2>
                    <p className="mt-4">${price} USD</p>
                  </div>
                </Link>
              </div>
            </div>
          </>
        )
      )}
    </Fragment>
  )
}
