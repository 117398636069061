import React, { Fragment } from "react"
import { InstantSearch, connectRefinementList } from "react-instantsearch-dom"
import algoliasearch from "algoliasearch/lite"
import { orderBy } from "lodash"
import { Link } from "gatsby"

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const CategoryFilter = ({ items, limit }) => {
  return (
    <Fragment>
      {items.map(
        (item, i) =>
          i < limit && (
            <div class="card compact rounded-full bg-base-100 shadow-lg dark:bg-base-100 text-center mt-8 sm:mt-0">
              <div class="card-body" key={item.label}>
                <Link
                  class="flex justify-center w-full"
                  to={`/search?query=&filter=&industry=${item.label}`}
                >
                  <p class="text-base-content font-bold">
                    {item.label.toUpperCase()}
                  </p>
                  <div class="text-base-content mx-4 font-bold">
                    {item.count}
                  </div>
                </Link>
              </div>
            </div>
          )
      )}
    </Fragment>
  )
}

const CustomCategoryFilter = connectRefinementList(CategoryFilter)

const Categories = () => {
  return (
    <>
      <InstantSearch searchClient={searchClient} indexName="brandshore">
        <CustomCategoryFilter
          attribute="data.industry"
          transformItems={items => orderBy(items, "label", "asc")}
          translations={{
            noResults: "No results",
            submitTitle: "Submit your search query.",
            resetTitle: "Clear your search query.",
            placeholder: "Search here...",
          }}
          limit={150}
        />
      </InstantSearch>
    </>
  )
}

export default Categories
